import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Link } from "gatsby"
import * as fetch from "node-fetch"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { ExclamationCircle, XCircle, CheckCircle } from "@heroicons/react/solid"
import SbEditable from "storyblok-react"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Footer from "../components/Footer"

const schema = yup.object().shape({
  name: yup.string().required().min(3).max(100),
  email: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "please enter a valid email",
      { excludeEmptyString: true }
    )
    .required()
    .min(3)
    .max(100),
  country: yup.string().required(),
  terms: yup.bool().oneOf([true]),
  message: yup.string().required().min(10).max(500),
})

async function post_request(url, data) {
  let theUrl = `${url}/messages`
  let newData = Object.assign(data, { website: "www.from-contact.com" })
  return fetch(theUrl, {
    method: "post",
    mode: "cors", // no-cors, *cors, same-origin
    body: JSON.stringify(newData),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then(response => {
      if (response.ok) {
        return "true"
      } else {
        throw "There is something wrong"
      }
    })
    .catch(error => {
      return "false"
    })
}

const Contact = ({ data, location }) => {
  const [isSuccessful, setIsSuccessful] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async data => {
    setIsSuccessful(undefined)
    const today = new Date()
    data.date = today.toISOString()
    data.subject = "contactform"

    const apiURl =
      process.env.APIURL === undefined
        ? process.env.LOCALURL
        : process.env.APIURL

    const result = await post_request(apiURl, data)

    setIsSuccessful(result)
    if (result === "true") {
      reset("", {
        keepValues: false,
      })
    }

    return result
  }

  return (
    <Layout>
      <Seo title="Contact Shopsmart" pageName="contact" />
      <h1 class="sr-only">Welcome to Shopsmart. Say hi to us!</h1>
      <form
        className="space-y-8 mr-8 ml-8 pt-16"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h2>Contact Shopsmart</h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                We'd love to hear from you. Please fill out the form below if
                you wish to get in touch. We will try to get back to you within
                48 hours.
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name: <span className="text-xs">(required)</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-2 border-gray-300 rounded-md p-2"
                    {...register("name", {
                      required: true,
                      maxLength: 100,
                      minLength: 3,
                    })}
                  />

                  <p class="mt-2 text-md text-red-600" id="name-error">
                    <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      {errors.name?.message.length > 0 ? (
                        <svg
                          class="h-9 w-9 text-red-500 p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <p></p>
                      )}

                      {errors.name?.message}
                    </div>
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address: <span className="text-xs">(required)</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-2 border-gray-300 rounded-md p-2"
                    {...register("email", {
                      required: true,
                      maxLength: 100,
                      minLength: 3,
                      message: "hi",
                    })}
                  />
                  <p class="mt-2 text-md text-red-600" id="email-error">
                    <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      {errors.email?.message.length > 0 ? (
                        <svg
                          class="h-9 w-9 text-red-500 p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <p></p>
                      )}

                      {errors.email?.message}
                    </div>
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Country / Region: <span className="text-xs">(required)</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-2 border-gray-300 rounded-md p-2"
                    {...register("country", {
                      required: true,
                    })}
                  >
                    <option value="">-- Please select --</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="AlandIslands">Aland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="AntiguaandBarbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="BouvetIsland">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="BritishIndianOceanTerritory">
                      British Indian Ocean Territory
                    </option>
                    <option value="BruneiDarussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="CapeVerde">Cape Verde</option>
                    <option value="CaymanIslands">Cayman Islands</option>
                    <option value="CentralAfricanRepublic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos(Keeling)Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="CongoTheDemocraticRepublicofThe">
                      Congo, The Democratic Republic of The
                    </option>
                    <option value="CookIslands">Cook Islands</option>
                    <option value="CostaRica">Costa Rica</option>
                    <option value="CoteD'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="CzechRepublic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="DominicanRepublic">
                      Dominican Republic
                    </option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="ElSalvador">El Salvador</option>
                    <option value="EquatorialGuinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="FalklandIslands(Malvinas)">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="FrenchGuiana">French Guiana</option>
                    <option value="FrenchPolynesia">French Polynesia</option>
                    <option value="FrenchSouthernTerritories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="HeardIslandandMcdonaldIslands">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="HolySee(VaticanCityState)">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="HongKong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="IranIslamicRepublicof">
                      Iran, Islamic Republic of
                    </option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="IsleofMan">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="KoreaDemocraticPeople'sRepublicof">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KoreaRepublicsof">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="LaoPeople'sDemocraticRepublic">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="LibyanArabJamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="MacedoniaTheFormerYugoslavRepublicof">
                      Macedonia, The Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="MicronesiaFederatedStatesof">
                      Micronesia, Federated States of
                    </option>
                    <option value="MoldovaRepublicof">
                      Moldova, Republic of
                    </option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="NetherlandsAntilles">
                      Netherlands Antilles
                    </option>
                    <option value="NewCaledonia">New Caledonia</option>
                    <option value="NewZealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="NorfolkIsland">Norfolk Island</option>
                    <option value="NorthernMarianaIslands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="PalestinianTerritoryOccupied">
                      Palestinian Territory, Occupied
                    </option>
                    <option value="Panama">Panama</option>
                    <option value="PapuaNewGuinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="RussianFederation">
                      Russian Federation
                    </option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="SaintHelena">Saint Helena</option>
                    <option value="SaintKittsandNevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="SaintLucia">Saint Lucia</option>
                    <option value="SaintPierreandMiquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="SaintVincentandTheGrenadines">
                      Saint Vincent and The Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="SanMarino">San Marino</option>
                    <option value="SaoTomeandPrincipe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">SolomonIslands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">SouthAfrica</option>
                    <option value="SouthGeorgiaandTheSouthSandwichIslands">
                      South Georgia and The South Sandwich Islands
                    </option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="SvalbardandJanMayen">
                      Svalbard and Jan Mayen
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="SyrianArabRepublic">
                      Syrian Arab Republic
                    </option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="TanzaniaUnitedRepublicof">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="TrinidadandTobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="TurksandCaicosIslands">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="UnitedArabEmirates">
                      United Arab Emirates
                    </option>
                    <option value="UnitedKingdom">United Kingdom</option>
                    <option value="UnitedStates">United States</option>
                    <option value="UnitedStatesMinorOutlyingIslands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="VietNam">Viet Nam</option>
                    <option value="VirginIslandsBritish">
                      Virgin Islands, British
                    </option>
                    <option value="VirginIslandsU.S.">
                      Virgin Islands, U.S.
                    </option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <p class="mt-2 text-md text-red-600" id="country-error">
                    <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      {errors.country?.message.length > 0 ? (
                        <svg
                          class="h-9 w-9 text-red-500 p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <p></p>
                      )}

                      {errors.country?.message}
                    </div>
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Message:{" "}
                  <span className="text-xs">
                    <span className="text-xs">(required)</span>
                  </span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    type="textarea"
                    name="message"
                    id="message"
                    rows="5"
                    cols="10"
                    className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm border-2 border-gray-300 rounded-md p-2"
                    {...register("message", {
                      required: true,
                      maxLength: 100,
                      minLength: 3,
                    })}
                  />

                  <p class="mt-2 text-md text-red-600" id="message-error">
                    <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      {errors.message?.message.length > 0 ? (
                        <svg
                          class="h-9 w-9 text-red-500 p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <p></p>
                      )}

                      {errors.message?.message}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  {...register("terms", {
                    required: true,
                  })}
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="font-medium text-gray-700">
                  Terms and conditions
                </label>
                <p class="text-gray-500">
                  By ticking the box above you are confirming that you have read
                  and agree to our{" "}
                  <a
                    href="https://www.shopsmart.com.au/privacy-policy"
                    target="_blank"
                  >
                    privacy policy
                  </a>
                  .
                </p>
                <p class="mt-2 text-md text-red-600" id="terms-error">
                  <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {errors.terms?.message.length > 0 ? (
                      <svg
                        class="h-9 w-9 text-red-500 p-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <p></p>
                    )}
                    {errors.terms?.message.length > 0 ? (
                      <p>
                        You need to agree to the terms before you submuit this
                        form.{" "}
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        {isSuccessful === "false" ? (
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  An internal error occurred with your submission. Please try
                  again later.
                </h3>
              </div>
            </div>
          </div>
        ) : (
          <p> </p>
        )}

        {isSuccessful === "true" ? (
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">Success!</h3>
                <div class="mt-2 text-sm text-green-700">
                  <p>We will get back to you shortly.</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p> </p>
        )}

        <div className="pt-5 pb-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="w-32 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Send
              {isSuccessful === undefined ? (
                <svg
                  class="animate-spin ml-2 mr-3 h-5 w-7 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <p> </p>
              )}
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </Layout>
  )
}

export default Contact
